import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URL,
        redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_URL,
        responseType: "token"
      }
    }
  });

const authProvider = {
    login: async ({provider}) => {
        Auth.federatedSignIn({provider: provider})
            .then(user => {return Promise.resolve()});
    },

    logout: async () => {
        Auth.signOut();

        var keysToRemove = [];
        for (var i = 0, len = localStorage.length; i < len; i++) {
            var key = localStorage.key(i);
            
            if (key.indexOf('CognitoIdentityServiceProvider') === 0) {
                keysToRemove.push(key);
            }
        }

        for (i in keysToRemove) {
            localStorage.removeItem(keysToRemove[i]);
        }
        
        return Promise.resolve();
    },

    checkError: async (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },

    checkAuth: async () => {
        await Auth.currentSession().then(user => {
            return Promise.resolve(user);
        }).catch(err => {
            return Promise.reject();
        });
        return;
    },

    getPermissions: async () => {
        let user = await Auth.currentAuthenticatedUser().catch(err => { return });
        if (user === undefined) { return }
        let roles = user.signInUserSession.idToken.payload['custom:Roles'];

        let permissions = ['User'];

        if (roles !== null) {
            if (roles.includes('Workspaces Portal (Super Admin)')) {
                permissions = ['Super Admin', 'Self Admin'];
            } else if (roles.includes('Workspaces Portal (Workspace Admin)')) {
                permissions = ['Workspace Admin', 'Self Admin', 'AllWorkspacesView', 'ActionsView', 'DirectoriesView', 'BundlesView', 
                    'ImagesView', 'QuotasView', 'GroupsView', 'RebootWorkspace', 'StartStopWorkspace',
                    'RebuildWorkspace', 'RestoreWorkspace', 'TerminateWorkspace', 'ModifyWorkspace'];
            } else if (roles.includes('Workspaces Portal (Field Services)')) {
                permissions = ['Field Services', 'Self Admin', 'AllWorkspacesView', 'ActionsView', 'RebootWorkspace', 'StartStopWorkspace', 
                    'RebuildWorkspace', 'RestoreWorkspace'];
            } else if (roles.includes('Workspaces Portal (Service Desk)')) {
                permissions = ['Service Desk T1', 'Self Admin', 'AllWorkspacesView', 'ActionsView', 'RebootWorkspace', 'StartStopWorkspace'];
            } else if (roles.includes('Workspaces Portal (Provisioning Team)')) {
                permissions = ['Provisioning Team', 'AllWorkspacesView', 'ActionsView', 'DirectoriesView', 'BundlesView',
                    'ImagesView', 'QuotasView', 'GroupsView', 'ModifyWorkspace'];
            } else if (roles.includes('Workspaces Portal (Readonly)')) {
                permissions = ['Read Only', 'AllWorkspacesView'];
            }
        }

        return Promise.resolve(permissions);
    },

    getIdentity: async () => {
        let user = await Auth.currentAuthenticatedUser().catch(err => { return });
        if (user === undefined) { return }
        let roles = user.signInUserSession.idToken.payload['custom:Roles'];
        let username = user.signInUserSession.idToken.payload.email;

        return Promise.resolve({id: username, fullName: username + ' - ' + roles});
    }
}

export default authProvider;